import React from "react"
import { withPrefix } from "gatsby"
import Img from "gatsby-image"
import ReCAPTCHA from "react-google-recaptcha"
import useSiteMetadata from "../hooks/use-site-metadata"
import Layout from "../components/layout"

export default () => {
  const { site, topimg } = useSiteMetadata()

  return (
    <Layout>
      <Img
        className="hero is-fullheight bg"
        fluid={topimg.childImageSharp.fluid}
      />
      <section className="hero is-fullheight">
        <div className="hero-body">
          <div
            className="container box"
            style={{ background: `url(${withPrefix("/title-bg.png")})` }}
          >
            <h1 className="title is-3 has-text-white has-text-centered">
              {site.title}
            </h1>
            <h2 className="subtitle is-5 mt-1 has-text-white has-text-centered">
              {site.description}
            </h2>
          </div>
        </div>
      </section>
      <section className="section about">
        <div className="container">
          <h1 className="title">事業案内</h1>
          <p className="content is-size-5 has-text-centered">
            ウェブ上での業務の手助けをするシステム開発をメインに行なっています
          </p>
          <ul className="box">
            <li>業務用ウェブシステムの企画、設計、構築、保守。</li>
            <li>構築システムについてのインフラ整備、保守。</li>
            <li>構築したシステムの運用で生成された各種情報の整理・分析。</li>
            <li>スマートフォンアプリの企画、設計、構築、保守。</li>
            <li>ビックデータ解析、機械学習関連のシステム構築。</li>
          </ul>
        </div>
      </section>
      <section className="section inquiry">
        <div className="container">
          <h1 className="title">お問い合わせ</h1>
          <p className="content is-size-5 has-text-centered">
            ご不明な点やご質問がありましたら、ぜひお気軽にご連絡ください。
          </p>
          <form
            name="inquiry-littlehermit"
            method="POST"
            action="/thanks"
            data-netlify="true"
            data-netlify-recaptcha="true"
          >
            <input
              type="hidden"
              name="form-name"
              value="inquiry-littlehermit"
            />
            <div className="field columns is-desktop">
              <p className="control column">
                <label className="label">
                  <span>お名前</span>
                  <input
                    type="text"
                    name="name"
                    className="input"
                    placeholder="Name"
                  />
                </label>
              </p>
              <p className="control column">
                <label className="label">
                  <span>メールアドレス</span>
                  <input
                    type="email"
                    name="email"
                    className="input"
                    placeholder="Email"
                  />
                </label>
              </p>
            </div>
            <div className="field">
              <label className="control label">
                <span>お問い合わせ内容</span>
                <textarea
                  name="message"
                  className="textarea"
                  placeholder="Message"
                ></textarea>
              </label>
            </div>
            <ReCAPTCHA
              className="field is-grouped is-grouped-centered"
              sitekey={process.env.GATSBY_SITE_RECAPTCHA_KEY}
            />
            <div className="field is-grouped is-grouped-centered">
              <button
                className="control button is-medium is-primary"
                type="submit"
              >
                送信
              </button>
            </div>
          </form>
        </div>
      </section>
      <section className="section company">
        <div className="container">
          <h1 className="title">会社概要</h1>
          <ul>
            <li>
              <h2>会社名</h2>
              <p>littlehermit LLC.</p>
            </li>
            <li>
              <h2>所在地</h2>
              <p>東京都渋谷区恵比寿西1-33-6 JP ｎｏｉｅ　恵比寿西 1F</p>
            </li>
            <li>
              <h2>電話番号</h2>
              <p>03-4570-0593</p>
            </li>
            <li>
              <h2>設立</h2>
              <p>2015年8月</p>
            </li>
            <li>
              <h2>事業内容</h2>
              <p>
                ウェブ系のシステム開発
                <br />
                モバイルアプリ開発
                <br />
                データ分析、機械学習まわりのシステム開発
              </p>
            </li>
            <li>
              <h2>代表</h2>
              <p>野本 昌嗣</p>
            </li>
          </ul>
        </div>
      </section>
    </Layout>
  )
}
